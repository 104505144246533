import Audio from '../viewer/Audio';
import Folder from '../viewer/Folder';
import PDF from '../viewer/PDF';
import Note from '../viewer/Note';

const game = "sc";
const endpointKey = process.env.REACT_APP_ENDPOINTKEY;

export const getComponentTag = (icon, close) => {
    let components = {
        folder: Folder,
        txt: Note,
        audio: Audio,
        pdf: PDF
    };

    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} />
}

export const qna = (question, episode, cta) => {
    return fetch(`https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/8a3cea8d-c670-4914-a297-278566eeafe3/generateAnswer`, {
        method: 'POST',
        body: JSON.stringify({
            "question": question,
            "top": 1,
            "scoreThreshold": 80,
            "strictFilters": [{ "name": "episode", "value": `${game}${episode}.${cta}` }]
        }),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': endpointKey
        }
    });
};

const summer_camp_saved_messages = "summer_camp_saved_messages";

export const getSavedMessages = () => {
    return JSON.parse(localStorage.getItem(summer_camp_saved_messages) || "[]");
}

export const saveMessages = (messages = []) => {
    localStorage.setItem(summer_camp_saved_messages, JSON.stringify(messages));
}
